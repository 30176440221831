import client, { FetchApiResponse } from '@api/client';
import { DividendsRequestType } from '@models/Dividents';
import { FilteredHistoryType, HistoryDetailsDividendsResponseType, HistoryDetailsFIXDividendsServersType, HistoryDetailsFIXServersType, HistoryDetailsMTDividendsServersType, HistoryDetailsMTServersType, HistoryDetailsResponseType, HistoryDetailsType } from '@models/History';
import { PresetCreateModel, PresetModel, presetModelToApi } from '@models/Preset';
import { ServerModelApiCreate, ServerModelApiUpdate, ServerModel } from '@models/Server';
import { SettingsModel } from '@models/Settings';
import { BaseTemplateModel, ImportFinaltoType, TemplateCreateModel, TemplateModel, templateModelToApi } from '@models/Templates';
import { VersionType } from '@models/selectServer';
import { convertUpdateSwapReqestModeltoApi, convertValidationReqestModelToApi, UpdateSwapReqestModel, ValidationReqestModel, ValidationResponseModel } from '@models/Swaps';
import { TargetTemplateType } from 'types/commonTypes';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { TargetTypeEnum } from 'types/targetTypes';

export const API = {
    servers: {
        create: (data: ServerModelApiCreate): Promise<FetchApiResponse<ServerModel>> => client.post<ServerModel>('/api/servers/createServer', data),
        update: (data: ServerModelApiUpdate): Promise<FetchApiResponse<ServerModel>> => client.put<ServerModel>('/api/servers/updateServer', data),
        getAll: (): Promise<FetchApiResponse<ServerModel[]>> => client.get<ServerModel[]>('/api/servers/getAllServers'),
        getByType: (type: string): Promise<FetchApiResponse<any[]>> => client.get<ServerModel[]>(`/api/servers/getServerbyType?type=${type}`),
        delete: (id: string): Promise<FetchApiResponse<string>> => client.delete<string>(`/api/servers/deleteServer/${id}`),
    },
    version: {
        get: (): Promise<FetchApiResponse<VersionType>> => client.get<VersionType>('/api/version/getVersion'),
    },
    history: {
        get: (dinamicURL: string): Promise<FetchApiResponse<FilteredHistoryType>> => client.get<FilteredHistoryType>(`/api/history/getHistory?${dinamicURL}`),
        getDetailsMT: (id: string): Promise<FetchApiResponse<HistoryDetailsType<HistoryDetailsResponseType<HistoryDetailsMTServersType>>>> => client.get<HistoryDetailsType<HistoryDetailsResponseType<HistoryDetailsMTServersType>>>(`/api/history/getHistoryDetail?id=${id}`),
        getDetailsFIX: (id: string): Promise<FetchApiResponse<HistoryDetailsType<HistoryDetailsResponseType<HistoryDetailsFIXServersType>>>> => client.get<HistoryDetailsType<HistoryDetailsResponseType<HistoryDetailsFIXServersType>>>(`/api/history/getHistoryDetail?id=${id}`),
        getDetailsMTDevidends: (id: string): Promise<FetchApiResponse<HistoryDetailsType<HistoryDetailsDividendsResponseType<HistoryDetailsMTDividendsServersType>>>> => client.get<HistoryDetailsType<HistoryDetailsDividendsResponseType<HistoryDetailsMTDividendsServersType>>>(`/api/history/getHistoryDetail?id=${id}`),
        getDetailsDevidendsFIX: (id: string): Promise<FetchApiResponse<HistoryDetailsType<HistoryDetailsDividendsResponseType<HistoryDetailsFIXDividendsServersType>>>> => client.get<HistoryDetailsType<HistoryDetailsDividendsResponseType<HistoryDetailsFIXDividendsServersType>>>(`/api/history/getHistoryDetail?id=${id}`),
    },
    templates: {
        getAllTemplates: (): Promise<FetchApiResponse<BaseTemplateModel[]>> => client.get<BaseTemplateModel[]>('/api/templates/getAllTemplates/'),
        getTemplateByTypeSwaps: (): Promise<FetchApiResponse<BaseTemplateModel[]>> => client.get<BaseTemplateModel[]>(`/api/templates/getTemplatesByTypes?types=${TargetTemplateType.swapsBySymbol}&types=${TargetTemplateType.swapsByGroups}`),
        getTemplateByTypeDividens: (): Promise<FetchApiResponse<BaseTemplateModel[]>> => client.get<BaseTemplateModel[]>(`/api/templates/getTemplatesByTypes?types=${TargetTemplateType.dividends}`),
        getTemplateById: (id: string): Promise<FetchApiResponse<TemplateModel>> => client.get<TemplateModel>(`/api/templates/getTemplate/${id}`),
        create: (data: TemplateCreateModel & { swaps: any | undefined }): Promise<FetchApiResponse<BaseTemplateModel[]>> => client.post<BaseTemplateModel[]>(`/api/templates/createTemplate`, data),
        delete: (id: string): Promise<FetchApiResponse<string>> => client.delete<string>(`/api/templates/deleteTemplate/${id}`),
        copyTemplate: (id: string): Promise<FetchApiResponse<any>> => client.post<any>(`/api/templates/copyTemplate?id=${id}`),
        exportCSV: (id: string): Promise<FetchApiResponse<any>> => client.get<any>(`/api/templates/swaps/export/${id}`),
        update: (data: TemplateModel): Promise<FetchApiResponse<TemplateModel>> => client.put<TemplateModel>(`/api/templates/updateTemplate`, templateModelToApi(data)), // TODO
        validateImportFinalto: (data: ImportFinaltoType): Promise<FetchApiResponse<any>> => client.post<any>(`/api/templates/validateFinaltoSwapsFromFile?name=${data.name}&type=${data.type}&templateType=${data.templateType}&serverId=${data.serverId}`, {
            file: data.file,
        }, { headers: {'Content-Type': 'multipart/form-data'}}),
    },
    validateSwaps: {
        validateMt4Swaps: (data: ValidationReqestModel): Promise<FetchApiResponse<ValidationResponseModel[]>> => client.post<ValidationResponseModel[]>(`/api/mt4/validateSwaps`, convertValidationReqestModelToApi(data)),
        validateMt4SwapsBySymbol: (data: ValidationReqestModel): Promise<FetchApiResponse<ValidationResponseModel[]>> => client.post<ValidationResponseModel[]>(`/api/mt4/validateSymbolSwaps`, convertValidationReqestModelToApi(data)),
        validateMt5Swaps: (data: ValidationReqestModel): Promise<FetchApiResponse<ValidationResponseModel[]>> => client.post<ValidationResponseModel[]>(`/api/mt5/validateSwaps`, convertValidationReqestModelToApi(data)),
        validateMt5SwapsBySymbol: (data: ValidationReqestModel): Promise<FetchApiResponse<ValidationResponseModel[]>> => client.post<ValidationResponseModel[]>(`/api/mt5/validateSymbolSwaps`, convertValidationReqestModelToApi(data)),
        validateTakerFixSwaps: (data: ValidationReqestModel): Promise<FetchApiResponse<ValidationResponseModel[]>> => client.post<ValidationResponseModel[]>(`/api/takerFix/validateSwaps`, convertValidationReqestModelToApi(data)),
    },
    updateSwaps: {
        updateMt4Swaps: (data: UpdateSwapReqestModel): Promise<FetchApiResponse<any>> => client.post<UpdateSwapReqestModel>(`/api/mt4/updateSwaps`, convertUpdateSwapReqestModeltoApi(data)),
        updateMt4SwapsBySymbol: (data: UpdateSwapReqestModel): Promise<FetchApiResponse<any>> => client.post<UpdateSwapReqestModel>(`/api/mt4/updateSymbolSwaps`, convertUpdateSwapReqestModeltoApi(data)),
        updateMt5Swaps: (data: UpdateSwapReqestModel): Promise<FetchApiResponse<any>> => client.post<UpdateSwapReqestModel>(`/api/mt5/updateSwaps`, convertUpdateSwapReqestModeltoApi(data)),
        updateMt5SwapsBySymbol: (data: UpdateSwapReqestModel): Promise<FetchApiResponse<any>> => client.post<UpdateSwapReqestModel>(`/api/mt5/updateSymbolSwaps`, convertUpdateSwapReqestModeltoApi(data)),
        updateTakerFixSwaps: (data: UpdateSwapReqestModel): Promise<FetchApiResponse<any>> => client.put<UpdateSwapReqestModel>(`/api/takerFix/updateSwaps`, convertUpdateSwapReqestModeltoApi(data)),
        export: (data: UpdateSwapReqestModel): Promise<FetchApiResponse<any>> => client.post<any>(`/api/templates/swaps/export/modified`, convertValidationReqestModelToApi(data), { responseType: 'arraybuffer' }),
    },
    dividents: {
        adjustMt4Dividends: (data: DividendsRequestType): Promise<FetchApiResponse<any>> => client.post<DividendsRequestType>(`/api/mt4/adjustDividends`, {...data, symbols: data.swaps}),
        adjustMt5Dividends: (data: DividendsRequestType): Promise<FetchApiResponse<any>> => client.post<DividendsRequestType>(`/api/mt5/adjustDividends`, {...data, symbols: data.swaps}),
        adjustTakerFixDividends: (data: DividendsRequestType): Promise<FetchApiResponse<any>> => client.post<DividendsRequestType>(`/api/takerFix/adjustDividends`, data),
    },
    settings: {
        getSettings: (): Promise<FetchApiResponse<SettingsModel>> => client.get<SettingsModel>('/api/settings/getSettings/'),
        updateSettings: (data: SettingsModel): Promise<FetchApiResponse<SettingsModel>> => client.put<SettingsModel>('/api/settings/updateSettings', data),
    },
    presets: {
        get: (): Promise<FetchApiResponse<any[]>> => client.get<PresetModel[]>('/api/presets/getPresets/'),
        getByType: (type: TargetTypeEnum, templateType: TargetTemplateTypeEnum): Promise<FetchApiResponse<any[]>> => client.get<PresetModel[]>(`/api/presets/getPresets?type=${type}&templateType=${templateType}`),
        getById: (id: string): Promise<FetchApiResponse<any>> => client.get<PresetModel>(`/api/presets/getPreset/${id}`),
        delete: (id: string): Promise<FetchApiResponse<string>> => client.delete<string>(`/api/presets/deletePreset/${id}`),
        create: (data: PresetCreateModel | PresetModel): Promise<FetchApiResponse<any[]>> => client.post<PresetModel[]>(`/api/presets/createPreset`, presetModelToApi(data)),
        copy: (id: string): Promise<FetchApiResponse<any>> => client.post<PresetModel>(`/api/presets/copyPreset?id=${id}`),
        update: (data: PresetModel): Promise<FetchApiResponse<any>> => client.put<PresetModel>(`/api/presets/updatePreset`, presetModelToApi(data)),
    },
};
