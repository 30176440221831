/* eslint-disable @typescript-eslint/no-explicit-any */
import { NUM_INPUT_PRECISION } from '@constants/inputs';
import { RawCSVData } from '@models';
import { ValidateErrorType } from '@models/ImportTemplates';
import { isFilledString, isValidNumber } from '@utils';
import Big from 'big.js';
import { ConvertStringToAllDays, DaysEnum, EndOfWeekDaysList } from 'types/days';
import { ConvertStringToSwapTypes, SwapTypeEnum } from 'types/swapsTypes';
import { TargetTypeEnum } from 'types/targetTypes';

export const convertDataKeyToLowerCase = (item: RawCSVData) => {
    let newObj = {} as Record<string, any>;
    Object.keys(item).forEach((key: string) => {
        const newKey = key.toLowerCase().trim();
        newObj[newKey] = item[key] ?? '';
    });
    return newObj;
};

export const convertDataKeysToLowerCase = (data: RawCSVData[]) => data.map((item) => convertDataKeyToLowerCase(item));

export type TemplatesControllerSwapsCSVRecord = {
    symbol: string | null;
    shortswap: Big | null;
    longswap: Big | null;
    type: SwapTypeEnum | null;
    tripleswapday: DaysEnum | null;
};

enum CSV_KEYS {
    symbol = 'symbol',
    short = 'shortswap',
    long = 'longswap',
    type = 'type',
    tsd = 'tripleswapday',
}

export const parseCSV = (
    rawData: RawCSVData[],
    serverType?: TargetTypeEnum,
): {
    parsedData: TemplatesControllerSwapsCSVRecord[];
    errors: ValidateErrorType[];
} => {
    if (!rawData || !Array.isArray(rawData) || rawData.some((item) => typeof item !== 'object'))
        return {
            parsedData: [],
            errors: [{ id: 'rawCSVData', value: 'No Data', description: 'Raw CSV Data is not array' }],
        };
    const errors: ValidateErrorType[] = [];

    const dataWithKeysLower = convertDataKeysToLowerCase(rawData);
    const parsedData: TemplatesControllerSwapsCSVRecord[] = [];

    dataWithKeysLower.forEach((item, index) => {
        if (typeof item !== 'object') return null;
        const symbol =
            CSV_KEYS.symbol in item && isFilledString(item[CSV_KEYS.symbol]) ? item[CSV_KEYS.symbol].trim() : null;
        const shortswap =
            CSV_KEYS.short in item && isFilledString(item[CSV_KEYS.short]) && isValidNumber(+item[CSV_KEYS.short])
                ? Big(item[CSV_KEYS.short]).round(NUM_INPUT_PRECISION)
                : null;
        const longswap =
            CSV_KEYS.long in item && isFilledString(item[CSV_KEYS.long]) && isValidNumber(+item[CSV_KEYS.long])
                ? Big(item[CSV_KEYS.long]).round(NUM_INPUT_PRECISION)
                : null;
        const type =
            CSV_KEYS.type in item && isFilledString(item[CSV_KEYS.type])
                ? ConvertStringToSwapTypes(item[CSV_KEYS.type])
                : null;
        const tripleswapday =
            CSV_KEYS.tsd in item && isFilledString(item[CSV_KEYS.tsd])
                ? ConvertStringToAllDays(item[CSV_KEYS.tsd])
                : null;
        let isFoundErrors = false;
        if (symbol === null || symbol === '') {
            errors.push({
                id: `${index + 1}`,
                value: `Row ${index + 1}. Symbol`,
                description: 'Invalid format or field is empty',
            });
            isFoundErrors = true;
        }
        if (longswap === null) {
            errors.push({
                id: `${index + 1}`,
                value: `Row ${index + 1}. Long`,
                description: `Invalid format or field is empty`,
            });
            isFoundErrors = true;
        }
        if (shortswap === null) {
            errors.push({
                id: `${index + 1}`,
                value: `Row ${index + 1}. Short`,
                description: `Invalid format or field is empty`,
            });
            isFoundErrors = true;
        }
        if (parsedData.find((e) => e.symbol === symbol)) {
            errors.push({
                id: `${index + 1}`,
                value: `Row ${index + 1}`,
                description: `"${symbol}" Symbol name must be unique`,
            });
            isFoundErrors = true;
        }
        if (type === null && CSV_KEYS.type in item && isFilledString(item[CSV_KEYS.type])) {
            errors.push({
                id: `${index + 1}`,
                value: `Row ${index + 1}. Type`,
                description: 'Invalid format or field is empty',
            });
            isFoundErrors = true;
        }
        if (tripleswapday === null && CSV_KEYS.tsd in item && isFilledString(item[CSV_KEYS.tsd])) {
            errors.push({
                id: `${index + 1}`,
                value: `Row ${index + 1}. Triple Swap Day`,
                description: 'Invalid format or field is empty',
            });
            isFoundErrors = true;
        }
        if (
            EndOfWeekDaysList.find((e) => e.key === tripleswapday) &&
            (serverType === TargetTypeEnum.mt4 || serverType === TargetTypeEnum.takerFix)
        ) {
            errors.push({
                id: `${index + 1}`,
                value: `Row ${index + 1}. `,
                description: 'Triple Swap Day=Saturday/Sunday/All Week is not supported for MT4 and Taker FIX',
            });
            isFoundErrors = true;
        }
        if (isFoundErrors) return null;
        parsedData.push({
            symbol,
            shortswap,
            longswap,
            type,
            tripleswapday,
        });
    });

    return {
        parsedData,
        errors,
    };
};
