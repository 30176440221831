import { yupCommonString, yupEntityNameValidationWithSpaces } from '@utils/yupValidations';
import * as yup from 'yup';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { TargetTypeEnum } from 'types/targetTypes';
import { templatesControllerStore } from '@store/templatesControllerStore';
import { BaseTemplateModel } from '@models/Templates';

export const schemaImportFinalto = yup.object().shape({
    name: yupEntityNameValidationWithSpaces
        .max(50, 'Must be less than 50 characters')
        .default('')
        .test('uniqueName', 'This name already exists', (value, { parent: { id } }) => {
            const templates: BaseTemplateModel[] = templatesControllerStore.data;
            return !templates.find((m) => (m.name ?? '').toLowerCase() === value.toLowerCase() && m.id !== id);
        })
        .required('Required field'),
    type: yup.mixed().oneOf(Object.values(TargetTypeEnum)).default(TargetTypeEnum.mt4).required('Required field'),
    templateType: yup
        .mixed()
        .oneOf(Object.values(TargetTemplateTypeEnum))
        .default(TargetTemplateTypeEnum.swapsByGroups)
        .required('Required field'),
    serverId: yupCommonString.default('').required('Required field'),
    file: yup
        .mixed()
        .default(undefined)
        .test('isCSVorXLS', 'Invalid file extension! Allowed only CSV or XLSX format', (value) => {
            if (!value) return true;
            if (
                value.type === 'text/csv' ||
                value.type === '.csv' ||
                value.name.endsWith('.csv') ||
                value.name.endsWith('.xlsx')
            ) {
                return true;
            }
            return false;
        })
        .required('Required field'),
});
